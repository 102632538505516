import React, { useEffect, useRef, useState } from "react";
import ButtonCustom from "../../frontv2.0/common/button/ButtonCustom";
import InputCustom from "../../frontv2.0/common/form/InputCustom";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import Sidebar from "../sidebar/Sidebar";
import SessionService from "../../../network/services/SessionService";
import { UNIT_OPTIONS, INTERVAL_OPTIONS } from '../ConfigConstants/ConfigConstants';
import Loader from "../../../components/frontv2.0/common/loader/Loader";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { PrimeIcons } from 'primereact/api';
import { Button } from 'primereact/button';
import { ConfirmPopup, confirmPopup } from 'primereact/confirmpopup';
import AdminHeader from "../header/AdminHeader";
import './style.css';
import moment from "moment/moment";
import ReactPaginate from "react-paginate";
import { RadioButton } from 'primereact/radiobutton';
import { InputSwitch } from 'primereact/inputswitch';


const DiscountCode = () => {
    const [state, setState] = useState({
        sessionId: "",
        name: "",
        sessionName: "",
        expirationDate: "",
        isFormVisible: true,
        edition: false,
        // characters: 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%&*',
        productType: "",
        discountType: "",
        value: "",
        maximalTokens: "",
        remainingTokens: "",
        status: "",
        closed: '',
        code: "",
        expiredAt: "",
        couponId: "",
        characters: 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789',


    });
    const toast = useRef(null);
    const [id, setId] = useState("");
    const [attempt, setAttempt] = useState("");
    const [value, setValue] = useState("");

    const [session, setSession] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [visible, setVisible] = useState(false);
    const [rescheduleDateSelected, setRescheduleDateSelected] = useState(false);
    const [isFormOpen, setIsFormOpen] = useState(false);
    const [showForm, setShowForm] = useState(false);
    const [amount, setAmount] = useState('');
    const [percentage, setPercentage] = useState("");
    const [expirationDate, setExpirationDate] = useState("");
    const [newDate, setNewDate] = useState("");
    const [sessionCourse, setSessionCourse] = useState("");
    const [selectedValue, setSelectedValue] = useState('');
    const [selectedOption, setSelectedOption] = useState("");
    const [showConfigurationPanel, setShowConfigurationPanel] = useState(false);
    const [sessionSubscriptionStatusSetting, setSessionSubscriptionStatusSetting] = useState('');
    const [courseId, setCourseId] = useState('');
    const [activeSession, setActiveSession] = useState('');
    const [methodApplyingDiscount, setMethodApplyingDiscount] = useState('');
    const [maximumToken, setMaximumToken] = useState('');
    const [courseData, setCourseData] = useState('');
    const [alphanumeriqueCode, setAlphanumeriqueCode] = useState('');
    const [name, setName] = useState("");
    const [code, setCode] = useState("");
    const [reductionType, setReductionType] = useState('');
    const [activeStatus, setActiveStatus] = useState('');
    const [couponData, setCouponData] = useState([]);
    const [selectedCoupon, setSelectedCoupon] = useState(null);
    const [itemPerPage, SetItemPerPage] = useState(10);
    const [statusTrueOrFalse, setStatusTrueOrFalse] = useState(null);
    const [filterValue, setFilterValue] = useState(null);
    const [lastPage, setLastPage] = useState(1);
    const [checked, setChecked] = useState(false);
    const [isToggle, setIsToggle] = useState(false);
    const [toggle, setToggle] = useState("");



    const handleToggleChange = (e) => {
        setIsToggle(true);
    };

    const confirm = () => {
        let newToggle;

        if (checked) {
            setIsToggle(false);
            //  setChecked(false);
            newToggle = false;
        } else {
            setIsToggle(false);
            //  setChecked(true);
            newToggle = true;
        }

        let data = { isActiveCoupon: newToggle };

        SessionService.getSettings(data, (response) => {

            if (response && response.success) {
                // setChecked(false);
                setChecked(!checked)
                showSuccess(response.message);

            } else {
                showError(response ? response.message : "Unknown error");


            }
            setIsLoading(false);
        });
    };


    const handleOptionSelection = (value) => {


        setStatusTrueOrFalse(value);
    };

    const handleFilterSelection = (value) => {


        setFilterValue(value);
    };



    const publicationClose = {
        published: "PUBLISHED",
        unpublished: "UNPUBLISHED",
    };


    const toggleConfigurationPanel = () => {
        setShowConfigurationPanel(!showConfigurationPanel);
    };





    const futureIntervals = couponData.map((item, index) => {
       
        return {
            id: item.id,
            name: item.name,
            productType: item.productType,
            discountType: item.discountType,
            value: item.value,
            maximalTokens: item.maximalTokens,
            status: item.status,
            closed: item.closed,
            code: item.code,
            remainingTokens: item.remainingTokens,
            expiredAt: moment(item.expiredAt).format("MMMM DD, YYYY"),
            createAt: moment(item.createdAt).format("MMMM DD, YYYY")
        };
    });



    const genereCode = () => {
        const length = 10; // Longueur du code généré
        let result = '';
        const charactersLength = state.characters.length;
        for (let i = 0; i < length; i++) {
            result += state.characters.charAt(Math.floor(Math.random() * charactersLength));
        }

        setAlphanumeriqueCode(result)

        setState((prevState) => ({
            ...prevState,
            code: result,
            error: false
        }));
    };


    const handleCodeChange = (event) => {
        const code = event.target.value;
        setCode(code);

    };

    const handleNewDateChange = (event) => {
        const value = event.target.value;
        setNewDate(value);

    };

    const handleExpirationtDateChange = (event) => {
        const value = event.target.value;

        setExpirationDate(value);
        setState((prevState) => ({
            ...prevState,
            expiredAt: value
        }));

    };



    const onStatusChange = (event) => {

        const value = event.target.value;
        setSelectedValue(value);
        setState((prevState) => ({
            ...prevState,
            status: value
        }));

    };

    const handleAmountChange = (event) => {
        let amount = event.target.value;

        setAmount(amount);
    };

    const handleMaximumTokenChange = (event) => {
        let maximumToken = event.target.value;

        setMaximumToken(maximumToken);
    };

    const handlePercentageChange = (event) => {
        const percentage = event.target.value;
        setAmount(percentage)

    }

    const handleKeyPress = (event) => {
        const keyCode = event.keyCode || event.which;
        const keyValue = String.fromCharCode(keyCode);
        const newValue = event.target.value + keyValue;
        if (newValue > 100) {
            event.preventDefault(); // Empêche la saisie du caractère
        }
    };


    const confirm1 = (event) => {
        event.preventDefault();
        confirmPopup({
            target: event.currentTarget,
            message: 'Are you sure you want to proceed?',
            icon: 'pi pi-exclamation-triangle',
            defaultFocus: 'accept',
            // accept: this.state.accept,
            accept: () => {
                const id = state.couponId;

                SessionService.DeleteCoupon(id, (response) => {
                    if (response != null) {
                        if (response.success) {
                            showSuccess("Deletion successful");

                            showAllCoupon();
                        } else {
                            showError(response.message);
                        }
                    } else {
                        showError("Unknown error");
                    }
                });
                resetForm();
                // setVisible(false)
                setIsFormOpen(false)
                // console.log('User clicked on Yes');
                setIsLoading({ isLoading: false });
            },

            reject: () => {
                // setVisible(false)
                setIsFormOpen(false)
                resetForm();
            }
        });
    };



    const handleCancelClick = () => {
        setIsFormOpen(false)
        resetForm();
        setIsToggle(false)
    };

    const handleEdit = (id) => {
        setIsFormOpen(true)
        const coupon = couponData.find((coupon) => coupon.id === id);
        if (coupon) {

            const {
                id
            } = coupon;


            setState({
                ...state,
                couponId: id,

            });
        }
    };


    const handleCloseForm = () => {
        setShowForm(false);
        setIsFormOpen(false)
        setIsToggle(false)

    };

    function renderRows() {
        return futureIntervals.map((rowData) => {
            const publicationStatus = rowData.status === 'PUBLISHED' ? (
                <span className="badge badge-sm bg-gradient-success text-center">{rowData.status}</span>
            ) : (
                <span className="badge badge-sm bg-gradient-warning text-center">{rowData.status}</span>
            );


            const activeStatus = rowData.closed ? (
                <span className=" badge badge-sm bg-gradient-success text-center">Opened</span>
            ) : (
                <span className="badge badge-sm bg-gradient-danger text-center ">Closed</span>
            );
            return {
                ...rowData,
                publicationStatus: publicationStatus,
                activeStatus: activeStatus,
            };
        });
    }
    
    const show = (e) => {
        e.preventDefault();
        setVisible(true);
    };

    const showSuccess = (message) => {
        toast.current.show({
            severity: "success",
            summary: "Success",
            detail: message,
            life: 5000,
        });
    };

    const showError = (message) => {
        toast.current.show({
            severity: "error",
            summary: "Error",
            detail: message,
            life: 3000,
        });
    };

    const handleButtonClick = () => {
        setVisible(true);
        setRescheduleDateSelected(false)
        setNewDate("")
        setExpirationDate("")
        setSessionCourse("")
        setSelectedValue("")
        setMaximumToken("")
        setAlphanumeriqueCode("")
        setName("")
        setActiveStatus("")
        setReductionType("")
        setSelectedCoupon("")
        setStatusTrueOrFalse(null);
        setFilterValue(null);

    };

    const onHide = () => {
        resetForm();

    };

    const hide = () => {



        setState({
            ...state,
            isFormVisible: false
        });
    }

    const resetForm = () => {
        setValue("");
        setSession('');
        setAttempt('');
        setVisible(false);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        setIsFormOpen(false);
        setShowForm(false);
        setSession('');
        setPercentage('');
        setAmount('');
        onHide();
        setCourseId('');
        setName('');
        setActiveStatus("")
        setReductionType("");
        setStatusTrueOrFalse(null);

    };



    const handlePageClick = (page) => {
        let index = page.selected + 1;
        SessionService.getAllCoupons(
            index,
            itemPerPage,
            (response) => {
                setLastPage(response.total_pages)
                setCouponData(response.data)

            }
        );
    };



    const showAllCoupon = () => {
        setIsLoading(true);

        SessionService.getAllCoupons(1, itemPerPage, (response) => {
            // console.log(couponData)
            if (response) {
                if (response.status) {

                    setLastPage(response.total_pages);
                    setCouponData(response.data)
                } else {
                    showError(response.message);
                }
            } else {
                showError("Unknown error");
            }

            setIsLoading(false);
        });
    };


    const cancelFilter = (e) => {
        setActiveSession("")
        setSessionCourse("")
        setValue("")
        setNewDate("")
        setExpirationDate("")
        setCode("")
        setSelectedCoupon("")
        setState({ discountType: "" });
        setReductionType("")
        showAllCoupon("");
        setStatusTrueOrFalse(null)
        setFilterValue(null)
        setName("")

    };

    const getCouponFilter = () => {

        let data = {

            name: name,
            productType: sessionCourse,
            discountType: reductionType,
            status: activeSession,
            code: code,
            createdAt: newDate,
            expiredAt: expirationDate,
            closed: filterValue,

        }
        SessionService.getCouponFilter(data, (response) => {
            if (response) {

                setCouponData(response.data)
                setLastPage(response.total_pages)

            }
        });
    };


    const handleActiveSessionFilterChange = (e) => {
        setActiveSession(e.target.value);
    };


    const onSessionCourseChange = (e) => {
        const sessionCourse = e.target.value;
        setSessionCourse(sessionCourse);
        setState((prevState) => ({
            ...prevState,
            productType: sessionCourse
        }));
    };


    const onNameChange = (e) => {
        const name = e.target.value;
        setName(name);
    };

    const onValueReductionTypeChange = (e) => {
        const reductionType = e.target.value;
        setReductionType(reductionType);
        setState((prevState) => ({
            ...prevState,
            discountType: reductionType
        }));

    };



    const handleOnChange = (e) => {
        const { name, value } = e.target;
        setState({
            ...state,
            [name]: value,
        });

    };



    const handleEditClick = (id) => {
        setId(id)
        setShowForm(true);
        const coupon = couponData.find((coupon) => coupon.id === id);

        if (coupon) {

            const {
                name,
                productType,
                discountType,
                value,
                maximalTokens,
                remainingTokens,
                status,
                closed,
                code,
                expiredAt,
                id
            } = coupon;

            let initialStatusTrueOrFalse = closed === true ? true : false;
            setState({
                ...state,
                name: name,
                productType: productType,
                discountType: discountType,
                value: value,
                maximalTokens: maximalTokens,
                remainingTokens: remainingTokens,
                status: status,
                closed: closed,
                code: code,
                expiredAt: expiredAt,
                couponId: id,

            });
            //Edition du coupon
            setStatusTrueOrFalse(initialStatusTrueOrFalse);
        }

    };



    const createCoupon = () => {
        let data = {
            name: name,
            product_type: sessionCourse,
            discount_type: reductionType,
            value: amount,
            status: selectedValue,
            code: alphanumeriqueCode,
            maximal_tokens: maximumToken,
            expired_at: expirationDate,
            closed: statusTrueOrFalse,
        };

        SessionService.CreateCoupon(data, (response) => {
            if (response != null) {
                if (response.success) {
                    showSuccess(response.message);
                    showAllCoupon();
                } else {
                    showError(response.message);
                    showAllCoupon();
                }
            } else {
                showError("Unknown error");
                // showSession()
            }
        });
    };




    const EditCoupon = () => {

        let data = {
            session_id: state.couponId,
            name: state.name,
            product_type: state.productType,
            discount_type: state.discountType,
            value: state.value,
            maximal_tokens: state.maximalTokens,
            status: state.status,
            closed: statusTrueOrFalse,
            code: state.code,
            expired_at: state.expiredAt
        };


        SessionService.EditCoupon(state.couponId, data, (response) => {
            if (response != null) {
                if (response.success) {

                    showSuccess(response.message);
                    showAllCoupon();
                } else {
                    showError(response.message);
                }
            } else {
                showError("Unknown error");
            }
        });
    }


    const toggleDatabaseValue = () => {

        SessionService.toggleDatabaseValue((response) => {
            // console.log(response);
            if (response) {
                // console.log(response)
                setChecked(response.isActiveCoupon)

            }
        });
    };

    useEffect(() => {
        showAllCoupon();
        toggleDatabaseValue()

    }, []);


    return (
        <>
            <Sidebar />
            <Toast ref={toast} position="top-right" />
            {isLoading ? (

                <div className="tw-flex tw-h-full tw-flex-col md:tw-justify-center tw-justify-center tw-items-center mt-3">

                    <Loader />

                </div>
            ) : (

                <>
                    <main className="mainAdmin r ms-100">
                        <AdminHeader location="Discount" />

                        {/* <div className="row mb-6"> */}
                        <div className={`row ${showConfigurationPanel ? 'mb-2' : 'mb-6'}`}>
                            <div className="col-12">
                                <nav className="navbar navbar-expand-lg blur border-radius-lg top-0 z-index-3 shadow position-absolute mt-4 py-2 start-0 end-0 mx-4">
                                    <div className="container-fluid ps-2 pe-0">
                                        <a
                                            className="navbar-brand font-weight-bolder ms-lg-0 ms-3"
                                        >
                                            Coupon reduction configuration panel
                                        </a>
                                        <button
                                            className="navbar-toggler shadow-none ms-2"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#navigation2"
                                            aria-controls="navigation"
                                            aria-expanded="false"
                                            aria-label="Toggle navigation"
                                            onClick={toggleConfigurationPanel}
                                        >
                                            {!showConfigurationPanel ? 'open' : 'close'}
                                        </button>
                                        <div className="collapse navbar-collapse" id="navigation2">
                                            <ul className="navbar-nav mx-auto">
                                                <li className="nav-item">
                                                    <a
                                                        href="javascript:;"
                                                    // data-bs-toggle="modal"
                                                    // data-bs-target="#exampleModal"
                                                    >
                                                        <div className="tw-flex tw-flex-row tw-space-x-4 mt-1" >
                                                            <ButtonCustom
                                                                label="ADD A DISCOUNT COUPON"
                                                                onClick={handleButtonClick}
                                                                classes="tw-bg-primary hover:tw-bg-primary-900 tw-text-white"
                                                            />
                                                        </div>
                                                    </a>
                                                </li>
                                            </ul>
                                            <ul className="navbar-nav d-lg-block d-none">
                                                <li className="nav-item">
                                                    <span
                                                        className="material-icons"
                                                        style={{ cursor: 'pointer' }}
                                                        onClick={toggleConfigurationPanel}
                                                    >
                                                        {!showConfigurationPanel ? 'expand_more' : 'expand_less'}
                                                    </span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </nav>

                                {showConfigurationPanel ? (
                                    <div className="row">
                                        <div className="col-lg-12 col-md-10 mx-auto mt-7">
                                            <div className="card">
                                                <div className="card-header p-3">
                                                    <div>
                                                        <h5>Settings</h5>
                                                        <h6>Enable or disable all coupons</h6>

                                                        <div className="card flex justify-content-center">
                                                            <InputSwitch checked={checked} onChange={handleToggleChange} />
                                                        </div>
                                                    </div>


                                                    <hr />
                                                    <div>
                                                        <h5>Filter</h5>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-4">
                                                            <select
                                                                className="form-control"
                                                                name="openStatus"
                                                                value={activeSession}
                                                                onChange={handleActiveSessionFilterChange}
                                                            >
                                                                <option value="">--Publication Status--</option>
                                                                <option value="PUBLISHED">PUBLISHED</option>
                                                                <option value="UNPUBLISHED">UNPUBLISHED</option>
                                                            </select>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <select
                                                                className="form-control"
                                                                name="publicationStatus"
                                                                value={sessionCourse}
                                                                onChange={onSessionCourseChange}
                                                            >
                                                                <option value="">--Product type--</option>
                                                                <option value="COURSE">COURSE</option>
                                                                <option value="SESSION">SESSION</option>
                                                            </select>
                                                        </div>

                                                        <div className="col-md-4">
                                                            <select
                                                                className="form-control"
                                                                name="publicationStatus"
                                                                value={reductionType}
                                                                onChange={onValueReductionTypeChange}
                                                            >
                                                                <option value="">--Discount type--</option>
                                                                <option value="Fixed amount">FIXED AMOUNT</option>
                                                                <option value="PERCENTAGE">PERCENTAGE</option>
                                                            </select>
                                                        </div>

                                                        <div className="col-md-4">
                                                            <span>Discount code</span>
                                                            <input
                                                                type="text"

                                                                name="code"
                                                                value={code}
                                                                onChange={handleCodeChange}
                                                                style={{ marginBottom: "6px" }}
                                                                placeholder="Discount code"
                                                                className="form-control"                                                            // onChange={handleFormChange}
                                                            />

                                                        </div>


                                                        <div className="col-md-4">
                                                            <span>Creation date</span>
                                                            <div className="tw-mt-0" >

                                                                <input
                                                                    type="date"
                                                                    value={newDate}
                                                                    onChange={handleNewDateChange}
                                                                    className="form-control"                                                            // onChange={handleFormChange}
                                                                />

                                                            </div>
                                                        </div>


                                                        <div className="col-md-4 mt-0">
                                                            <span>Expired date</span>
                                                            <div className="tw-mt-0">

                                                                <input
                                                                    type="date"
                                                                    value={expirationDate}
                                                                    onChange={handleExpirationtDateChange}
                                                                    className="form-control"                                                             // onChange={handleFormChange}
                                                                />

                                                            </div>
                                                        </div>
                                                        <div className="col-md-4 mt-0">
                                                            <span>Product status</span>
                                                            <div className="card flex justify-content-center">
                                                                <div>
                                                                    <label>
                                                                        <RadioButton
                                                                            inputId="trueOption"
                                                                            name="option"
                                                                            value={true}
                                                                            onChange={() => handleFilterSelection(true)}
                                                                            checked={filterValue === true}
                                                                        />
                                                                        <span>Opened</span>
                                                                    </label>
                                                                </div>
                                                                <div>
                                                                    <label>
                                                                        <RadioButton
                                                                            inputId="falseOption"
                                                                            name="option"
                                                                            value={false}
                                                                            onChange={() => handleFilterSelection(false)}
                                                                            checked={filterValue === false}
                                                                        />
                                                                        <span>Closed</span>
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>


                                                        <div className="col-md-4  mt-0">
                                                            <span>Name</span>
                                                            <div className="tw-mt-0">

                                                                <input
                                                                    type="texte"
                                                                    value={name}
                                                                    onChange={onNameChange}
                                                                    className="form-control"                                                             // onChange={handleFormChange}
                                                                />

                                                            </div>
                                                        </div>



                                                        <div className="col-md-4 mt-4">
                                                            <button
                                                                type="button"
                                                                className="btn btn-primary"
                                                                onClick={getCouponFilter}
                                                            >
                                                                Apply filter
                                                            </button>
                                                            &nbsp;&nbsp;&nbsp;
                                                            <button
                                                                type="button"
                                                                className="btn btn-outline-secondary"
                                                                onClick={cancelFilter}
                                                            >
                                                                Cancel
                                                            </button>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ) : null}
                                <hr></hr>

                            </div>
                        </div>


                        <Dialog
                            header="Setting up a discount coupon"
                            visible={visible}

                            style={{
                                width: '54vw',
                                height: '80vh',
                                marginLeft: '10vh',
                                marginTop: "-10vh"
                                // visible={showForm} 

                            }}
                            onHide={onHide}

                        >
                            <hr />


                            <form
                                id="create-session-form"
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    minHeight: "14vh",
                                    marginTop: "1px"


                                }}
                                onSubmit={handleSubmit}
                            >
                                <>
                                    <div className="tw-mb-0">
                                        <h3 className="tw-block tw-font-bold tw-text-primary-800 mt-2 tw-text-center tw-text-xl">

                                            Create a discount coupon
                                        </h3>
                                    </div>
                                    <div >

                                        <div className="tw-flex tw-justify-end">
                                            <button style={{ width: "250px", marginTop: "10px" }}
                                                type="button"
                                                className="tw-text-white tw-bg-primary tw-rounded tw-px-4 tw-py-2 tw-mr-5"

                                                onClick={genereCode}
                                            >
                                                GENERATE CODE
                                            </button>
                                            <input
                                                type="text"

                                                name="alphanumeriqueCode"
                                                value={alphanumeriqueCode}
                                                // onChange={handleFormChange}
                                                style={{ marginTop: "10px" }}

                                                placeholder="Discount code"
                                                className="form-control"                                                            // onChange={handleFormChange}
                                            />

                                        </div>


                                        <div className="tw-mt-4" style={{ width: "540px", marginBottom: "15px" }}>

                                            <option value="">Name</option>
                                            <input id="name" name="name" type="texte"
                                                className="form-control"                                                            // onChange={handleFormChange}
                                                onChange={onNameChange}
                                                value={name}
                                                placeholder="--Name--"
                                            />
                                        </div>

                                        <div style={{ marginBottom: "15px" }}
                                        >
                                            <span>Product type</span>

                                            <select
                                                id="valueSelect"
                                                className="form-control"                                                            // onChange={handleFormChange}
                                                value={sessionCourse}

                                                onChange={onSessionCourseChange}
                                                type="text"
                                            >
                                                <option value="">--Product type--</option>

                                                <option value="Session">Session</option>
                                                <option value="Course">Course</option>
                                            </select>
                                        </div>



                                        <div style={{ marginBottom: "15px" }}
                                        >
                                            <span>Discount type</span>

                                            <select
                                                id="valueSelect"
                                                className="form-control"                                                            // onChange={handleFormChange}
                                                value={reductionType}

                                                onChange={onValueReductionTypeChange}
                                                type="text"
                                            >
                                                <option value="">--Discount type--</option>

                                                <option value="Fixed amount">Fixed amount</option>
                                                <option value="Percentage">Percentage</option>
                                            </select>
                                        </div>

                                        {reductionType === "Fixed amount" && (

                                            <div
                                            >
                                                <div class="flex">
                                                    <div class="input-container">
                                                        <input id="amount" name="amount" type="number" min="1" style={{
                                                            width: "540px"
                                                        }}
                                                            max="10000"
                                                            className="form-control"                                                            // onChange={handleFormChange}
                                                            onChange={handleAmountChange}
                                                        />
                                                        <span class="currency">$</span>
                                                    </div>
                                                </div>

                                            </div>)}

                                        {reductionType === "Percentage" && (
                                            <div>


                                                <div class="flex">
                                                    <div class="input-container">
                                                        <input id="percentage" name="percentage" type="number" min="1" style={{
                                                            width: "540px"
                                                        }}
                                                            max="100"
                                                            className="form-control"                                                            // onChange={handleFormChange}
                                                            onChange={handlePercentageChange}
                                                            onKeyPress={handleKeyPress}
                                                        />
                                                        <span class="currency">%</span>
                                                    </div>
                                                </div>


                                            </div>


                                        )}


                                        <div style={{ marginBottom: "15px" }}

                                        >
                                            <span>Maximum token</span>
                                            <div class="flex">
                                                <div class="input-container">
                                                    <input id="Maximum token" name="Maximum token" type="number" min="1" style={{
                                                        width: "540px"
                                                    }}
                                                        max="10000"
                                                        className="form-control"                                                            // onChange={handleFormChange}
                                                        onChange={handleMaximumTokenChange}
                                                        value={maximumToken} />

                                                </div>
                                            </div>

                                        </div>

                                        <div
                                        >


                                        </div>

                                        <div style={{ marginBottom: "15px" }}
                                        >
                                            <span>Publication Status</span>

                                            <select
                                                style={{ marginTop: "1px" }}
                                                id="status"
                                                value={selectedValue}
                                                className="form-control"                                                            // onChange={handleFormChange}
                                                name="status"
                                                onChange={onStatusChange}
                                            >
                                                <option value="">--Publication Status--</option>
                                                <option value="PUBLISHED">
                                                    {publicationClose.published}
                                                </option>
                                                <option value="UNPUBLISHED">
                                                    {publicationClose.unpublished}
                                                </option>
                                            </select>

                                        </div>


                                        <div style={{ marginBottom: "15px" }}>
                                            <span>Expiration date</span>

                                            <input
                                                style={{ marginBottom: "3px" }}
                                                type="date"
                                                value={expirationDate}
                                                onChange={handleExpirationtDateChange}
                                                className="form-control"                                                            // onChange={handleFormChange}
                                                min={new Date().toISOString().split('T')[0]}
                                            />

                                        </div>


                                        <span>Product status</span>
                                        <div className="card flex justify-content-center">
                                            <div>
                                                <label>
                                                    <RadioButton
                                                        inputId="trueOption"
                                                        name="option"
                                                        value={true}
                                                        onChange={() => handleOptionSelection(true)}
                                                        checked={statusTrueOrFalse === true}
                                                    />
                                                    <span>Opened</span>
                                                </label>
                                            </div>
                                            <div>
                                                <label>
                                                    <RadioButton
                                                        inputId="falseOption"
                                                        name="option"
                                                        value={false}
                                                        onChange={() => handleOptionSelection(false)}
                                                        checked={statusTrueOrFalse === false}
                                                    />
                                                    <span>Closed</span>
                                                </label>
                                            </div>
                                        </div>

                                        <div style={{
                                            marginTop: "20px",

                                        }}>


                                            <ButtonCustom
                                                classes={
                                                    (sessionCourse === '' || name === '' || expirationDate === '' || reductionType === '' || maximumToken === '' || selectedValue === '' || alphanumeriqueCode === '' || statusTrueOrFalse === null)
                                                        ? "tw-bg-gray-300 tw-text-gray-500"
                                                        : "tw-bg-primary hover:tw-bg-primary-900 tw-text-white"
                                                }
                                                label="Create"
                                                onClick={createCoupon}
                                                icon="pi pi-sync"
                                                disabled={sessionCourse === '' || name === '' || expirationDate === '' || reductionType === '' || maximumToken === '' || selectedValue === '' || alphanumeriqueCode === '' || statusTrueOrFalse === null}
                                            />

                                        </div>
                                    </div>
                                </>



                            </form>
                        </Dialog>



                        <div className="card" style={{ marginTop: "-10px", marginLeft: "14px", marginRight: "14px" }}>
                            <div className="tw-flex tw-flex-col">
                                <div style={{ marginLeft: "", marginBottom: "0%" }}>

                                    <DataTable value={renderRows()} tableStyle={{ minWidth: '10rem' }} scrollable scrollHeight="550px" scrollWidth="100%">
                                        <Column field="name" header={<div style={{ width: '100%' }}>Name &nbsp;&nbsp;</div>}></Column>
                                        <Column field="productType" header="Product type"></Column>
                                        <Column field="discountType" header="Discount type"></Column>
                                        <Column field="value" header="Value"></Column>
                                        <Column field="maximalTokens" header="Maximum tokens"></Column>
                                        <Column field="remainingTokens" header="Remaining tokens"></Column>
                                        <Column field="publicationStatus" header="Publication status"></Column>
                                        <Column field="activeStatus" header="Product status"></Column>
                                        <Column field="code" header="Code"></Column>
                                        <Column field="createAt" header="Creation date"></Column>
                                        <Column field="expiredAt" header="Expiration date"></Column>

                                        <Column
                                            header="Actions"
                                            body={(rowData) => (
                                                <div>
                                                    <Button label="Edit" icon="pi pi-pencil" className="p-button-sm p-button-primary-800 mb-2" onClick={() => handleEditClick(rowData.id)} />
                                                    <Button label="DELETE" icon="pi-times" className="p-button-sm p-button-danger" onClick={() => handleEdit(rowData.id)} />


                                                </div>

                                            )}
                                        ></Column>
                                    </DataTable>
                                    <Dialog
                                        header="Configuration of editing a discount coupon"
                                        visible={showForm}
                                        style={{
                                            width: '54vw',
                                            height: '80vh',
                                            marginLeft: '10vh',
                                            marginTop: "-10vh"
                                            // visible={showForm} 

                                        }}

                                        onHide={handleCloseForm}
                                        onSubmit={handleSubmit}
                                    >
                                        <hr />
                                        <form>

                                            <div className="tw-mb-0">
                                                <h3 className="tw-block tw-font-bold tw-text-primary-800 mt-3 tw-text-center tw-text-xl">

                                                    Editing a discount coupon
                                                </h3>
                                            </div>


                                            <div >

                                                <div className="tw-flex tw-justify-end " style={{ marginBottom: "10px" }}>
                                                    <button style={{ width: "250px" }}
                                                        type="button"
                                                        className="tw-text-white tw-bg-primary tw-rounded tw-px-4 tw-py-2 tw-mr-5"

                                                        onClick={genereCode}
                                                    >
                                                        GENERATE CODE
                                                    </button>
                                                    <input
                                                        type="text"

                                                        name="alphanumeriqueCode"
                                                        value={state.code}
                                                        onChange={handleCodeChange}
                                                        placeholder="Discount code"
                                                        className="form-control"                                                            // onChange={handleFormChange}
                                                    />

                                                </div>



                                                <span>Name</span>
                                                <div style={{ marginBottom: "10px" }}>

                                                    <input
                                                        id="name"
                                                        name="name"

                                                        value={state.name}
                                                        type="text"
                                                        onChange={handleOnChange}
                                                        className="form-control"

                                                    />
                                                </div>

                                                <span>Product type</span>
                                                <div style={{ marginTop: "5px", marginBottom: "10px" }}>

                                                    <select
                                                        id="valueSelect"
                                                        className="form-control"
                                                        value={state.productType}
                                                        disabled
                                                        type="text"
                                                    >
                                                        <option value="Session">Session</option>
                                                        <option value="Course">Course</option>
                                                    </select>
                                                </div>


                                                <span>Discount type</span>
                                                <div style={{ marginBottom: "10px" }}>
                                                    <select
                                                        id="valueSelect"
                                                        className="form-control"
                                                        value={state.discountType} // Utilisez la valeur de state.productType pour sélectionner l'option correspondante
                                                        onChange={onValueReductionTypeChange}
                                                        type="text"
                                                    >
                                                        <option value="Fixed amount">Fixed amount</option>
                                                        <option value="Percentage">Percentage</option>
                                                    </select>

                                                </div>

                                                <span>Value</span>
                                                <div style={{ marginBottom: "20px" }}>

                                                    <input
                                                        id="value"
                                                        name="value"
                                                        placeholder=""
                                                        value={state.value}
                                                        type="number"
                                                        onChange={handleOnChange}
                                                        className="form-control"
                                                    />
                                                </div>

                                                <span>Maximum token</span>
                                                <div style={{ marginBottom: "20px" }}>

                                                    <input
                                                        max="10000"
                                                        min="1"
                                                        id="maximalTokens"
                                                        name="maximalTokens"
                                                        placeholder="Maximum token"
                                                        value={state.maximalTokens}
                                                        type="number"
                                                        onChange={handleOnChange}
                                                        className="form-control"
                                                    />
                                                </div>

                                                <span>Publication Status</span>
                                                <div style={{ marginBottom: "20px" }}>


                                                    <select
                                                        name="status"
                                                        className="form-control"
                                                        value={state.status} // Utilisez la valeur de state.productType pour sélectionner l'option correspondante
                                                        onChange={onStatusChange}
                                                        type="text"
                                                    >
                                                        {/* <option>
                                                                --Publication status--
                                                            </option> */}
                                                        <option value="UNPUBLISHED">
                                                            {
                                                                publicationClose.unpublished
                                                            }
                                                        </option>
                                                        <option value="PUBLISHED">
                                                            {publicationClose.published}
                                                        </option>


                                                    </select>
                                                </div>

                                                <span>Expiration Date</span>
                                                <div >
                                                    <input
                                                        style={{ marginBottom: "20px" }}
                                                        type="date"
                                                        name="expiredAt"
                                                        value={moment(state.expiredAt).format("YYYY-MM-DD")}
                                                        onChange={handleExpirationtDateChange}
                                                        className="form-control"                                                            // onChange={handleFormChange}
                                                    />
                                                </div>


                                                <div className="card flex justify-content-center ">

                                                    <span >Product status</span>
                                                    <div className="card flex justify-content-center" >
                                                        <div>
                                                            <label>
                                                                <RadioButton
                                                                    inputId="trueOption"
                                                                    name="option"
                                                                    value={statusTrueOrFalse}
                                                                    onChange={() => handleOptionSelection(true)}
                                                                    checked={statusTrueOrFalse === true}
                                                                />
                                                                <span>Opened</span>
                                                            </label>
                                                        </div>
                                                        <div>
                                                            <label>
                                                                <RadioButton
                                                                    inputId="falseOption"
                                                                    name="option"
                                                                    value={statusTrueOrFalse}
                                                                    onChange={() => handleOptionSelection(false)}
                                                                    checked={statusTrueOrFalse === false}
                                                                />
                                                                <span>Closed</span>
                                                            </label>
                                                        </div>
                                                    </div>


                                                </div>


                                                <div style={{ marginTop: "20px" }}>
                                                    <ButtonCustom

                                                        classes={"tw-bg-primary hover:tw-bg-primary-900 tw-text-white "}
                                                        label="Update"
                                                        icon={"pi pi-sync"}
                                                        onClick={EditCoupon}

                                                    />
                                                </div>
                                            </div>
                                        </form>
                                    </Dialog>


                                    <Dialog
                                        header="DELETE MODAL"
                                        visible={isFormOpen}
                                        style={{
                                            width: '54vw',
                                            height: '35vh',
                                            marginLeft: '10vh',
                                            marginTop: "-10vh"
                                            // visible={showForm} 

                                        }}

                                        onHide={handleCloseForm}
                                        onSubmit={handleSubmit}
                                    >
                                        <hr />
                                        <h1 className="tw-block tw-font-bold mt-3 tw-text-primary-800 tw-text-center">
                                            Are you sure you want to delete this line?
                                        </h1>



                                        <div className="tw-flex tw-justify-center mt-3">
                                            <ConfirmPopup />
                                            <div className="card button-container">
                                                <Button onClick={handleCancelClick} icon="pi pi-times" label="Cancel" className="p-button-danger"></Button>
                                            </div>
                                            &emsp;
                                            <Button onClick={confirm1} icon="pi pi-check" label="Confirm"></Button>
                                        </div>
                                    </Dialog>

                                    <Dialog
                                        header="DELETE MODAL"
                                        visible={isToggle}
                                        style={{
                                            width: '54vw',
                                            height: '35vh',
                                            marginLeft: '10vh',
                                            marginTop: "-10vh"
                                            // visible={showForm} 

                                        }}

                                        onHide={handleCloseForm}
                                        onSubmit={handleSubmit}
                                    >
                                        <hr />
                                        <h1 className="tw-block tw-font-bold mt-3 tw-text-primary-800 tw-text-center">
                                            Are you sure you want to perform this operation?
                                        </h1>



                                        <div className="tw-flex tw-justify-center mt-3">
                                            <ConfirmPopup />
                                            <div className="card button-container">
                                                <Button onClick={handleCancelClick} icon="pi pi-times" label="Cancel" className="p-button-danger"></Button>
                                            </div>
                                            &emsp;
                                            <Button onClick={confirm} icon="pi pi-check" label="Confirm"></Button>
                                        </div>
                                    </Dialog>


                                </div>
                            </div>
                        </div>
                        <div className="row ">
                            <div className="col-5 offset-5">
                                <div className="card-footer">
                                    <nav aria-label="...">
                                        <ReactPaginate
                                            previousLabel={"<"}
                                            nextLabel={">"}
                                            breakLabel={"..."}
                                            pageCount={lastPage}
                                            marginPagesDisplayed={3}
                                            pageRangeDisplayed={6}
                                            onPageChange={handlePageClick}
                                            containerClassName={
                                                "pagination justify-content-end mb-0"
                                            }
                                            pageClassName={"page-item"}
                                            previousClassName={"page-item"}
                                            previousLinkClassName={"page-link"}
                                            pageLinkClassName={"page-link"}
                                            nextClassName={"page-item"}
                                            nextLinkClassName={"page-link"}
                                            breakClassName={"page-item"}
                                            breakLinkClassName={"page-link"}
                                            activeClassName={"active"}
                                        />
                                    </nav>
                                </div>
                            </div>
                        </div>
                        <div>
                        </div>
                    </main>
                </>
            )
            }

        </>
    );
};

export default DiscountCode;