// SideBar.js

import React, {Component} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Link} from "react-router-dom";
import {getActiveTab, getCollapseKey, getRole, setActiveTab, setCollapseKey} from "../../../network/auth/Auth";
import Accordion from 'react-bootstrap/Accordion';
import routeCourse from "../../../Roles routes/routeCourse";
import routeAdmin from "../../../Roles routes/routeAdmin";
import routeAccounting from "../../../Roles routes/routeAccounting";
import routeSupport from "../../../Roles routes/routeSupport";
import routeInstructor from "../../../Roles routes/routeInstructor";
import {accordionNames} from "../../../constants/Constants"

export default class Sidebar extends Component {
    constructor(props) {
        super(props);

        this.state = {
            role: '',
            active: '0',
            routeToShow: [],
            activeTab: ''
        }
    }

    createLinks2 = () =>{
        let tab = [];
        accordionNames.map((prop, key) => {
             let val = this.createLinks(this.state.routeToShow, key+1);

             if(val)
                 tab.push(val);
         })
        return tab
    }

    createLinks = (routes, section) => {

        const routesHere = routes.filter(e => e.section === section);
        let routesRoutes = [];

        if(routesHere.length > 0){
            routesRoutes = routesHere.map((prop, key) => {
                        return (
                            <>
                                <li className="nav-item" key={key}  onClick={() => {
                                    setActiveTab(prop.path);
                                    setCollapseKey(prop.section - 1)
                                }}>
                                    <Link to={prop.path}
                                        className={this.state.activeTab !== prop.path ? "nav-link text-white" : "nav-link text-white active bg-gradient-info"}>
                                        <div
                                            className="text-white text-center me-2 d-flex align-items-center justify-content-center">
                                            <i className="material-icons opacity-10">{prop.icon}</i>
                                        </div>
                                        <span className="nav-link-text ms-1">{prop.name}</span>
                                    </Link>
                                </li>
                            </>
                        );
                    });
        }
         
        return (
            <>
            {routesRoutes.length > 0 ?
                <Accordion.Item eventKey={(section-1).toString()}>
                <Accordion.Header onClick={() => {
                    // setCollapseKey(0);
                }} style={{color: 'green'}}>{accordionNames[section-1]}</Accordion.Header>
                <Accordion.Body {...(this.state.role && {
                    style: {backgroundColor: "#333338"
                }
                })}>
                    <ul className="navbar-nav px-0">
                       {routesRoutes}
                    </ul>
                </Accordion.Body>
            </Accordion.Item> : null }
            </>
        )
    };

    componentWillMount() {

        const collapseIndex = getCollapseKey();

        // console.log('la section est '+collapseIndex)

        if (collapseIndex) {
            this.setState({active: collapseIndex});
        }

        // setCollapseKey('1');
        //console.log('la valeur qui vient du local est '+collapseIndex);

        const currentTab = getActiveTab();
        //this.setState({active: collapseIndex, activeTab: currentTab});
        this.setState({activeTab: currentTab});
    }

    componentDidMount = () => {
        const userRole = getRole();
        this.setState({role: userRole});
        if (userRole === 'ROLE_ADMIN') {
            this.setState({routeToShow: routeAdmin})
        }
        if (userRole === 'ROLE_COURSE') {
            this.setState({routeToShow: routeCourse})
        }
        if (userRole === 'ROLE_INSTRUCTOR') {
            this.setState({routeToShow: routeInstructor})
        }
        if (userRole === 'ROLE_ACCOUNTING') {
            this.setState({routeToShow: routeAccounting})
        }
        if (userRole === 'ROLE_SUPPORT') {
            this.setState({routeToShow: routeSupport})
        }
    }

    render() {
        return (
            <>

                <aside style={{width: '2200px'}}
                       className="sidenav navbar navbar-vertical
                navbar-expand-xs bg-gradient-dark"
                       id="sidenav-main">
                    <center>
                        <div className="sidenav-header">

                            <Link to="/" className="navbar-brand m-0">
                                <img
                                    src="https://utrains.org/wp-content/uploads/elementor/thumbs/utrain-p32wyg4nc23be364pi9ydxyz1qhz2wpdyf46nf0n40.png"
                                    className="navbar-brand-img h-100"/>
                            </Link>
                        </div>
                    </center>

                    <hr className="horizontal light mt-0 mb-2"></hr>

                    <div className=" w-auto  max-height-vh-100" id="sidenav-collapse-main">
                        <div>
                            <Accordion defaultActiveKey={this.state.active} alwaysOpen>
                                {this.createLinks2()}
                            </Accordion>
                        </div>
                    </div>
                    {/*  <div className="sidenav-footer position-absolute w-100 bottom-0 ">
                    <div className="mx-3">
                        <a className="btn bg-gradient-info mt-4 w-100"
                           href="https://www.creative-tim.com/product/material-dashboard-pro?ref=sidebarfree"
                           type="button">
                            <p>Powered <i className="icofont-heart-alt"></i> by utrains </p></a>
                    </div>
                </div>*/}
                </aside>
            </>

        )
    }
}
