import React, {Component} from "react";
import UserService from "../../network/services/UserService";
import './Footer.css';
import backendUrls from "../../network/urls/backendUrls";

const wordpressUrl = process.env.REACT_APP_WORDPRESS;

class Footer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            successMessage: false,
        }
    }

    handleFormChange = ($event) => {
        const {name, value} = $event.target
        this.setState({[name]: value});
    }

    notification = (message) => {
        this.setState({message: message, successMessage: true});
    }

    registerNewsletter = () => {
        let data = {email: this.state.email}
        UserService.registerForNewsletter(data,
            (response) => {
                this.notification("Your registration  has been done successfully");
                // const form = document.getElementById('newsletter-form');
                // form.reset();
            });
    }

    render() {
        return (

            <div className="colorgray">
                <div class="section footer-section" style={{background: "#000000"}}>

                    <div class="footer-widget-section">


                        <div class="container">
                            <div class="row">
                                <div class="col-lg-4 col-sm-6 col-md-6 order-md-1 order-lg-1">

                                    <div class="footer-widget">
                                        <div class="widget-logo">
                                            <a href={backendUrls.Wordpress.root}><img src="assets/images/logo2.png"
                                                                                      width={118} height={29}
                                                                                      alt="Logo"/></a>
                                        </div>

                                        <div class="widget-address">

                                            <p class="fontsizewelcome">Welcome to the Linux and DevOps system
                                                administration training
                                                program.</p>
                                            <p class="fontsizewelcome">Some of you may be asking the question what is
                                                Linux? Well it’s normal to
                                                ask if you are not an IT professional. If that is the case, then …

                                            </p>
                                        </div>


                                    </div>

                                </div>
                                <div class="col-lg-5 col-sm-3 order-md-3 order-lg-2">

                                    <div class="footer-widget-link">

                                        {/*     <div class="footer-widget">
                                                <h4 class="footer-widget-title">Category</h4>

                                                <ul class="widget-link">
                                                    <li><a href="#">Creative Writing</a></li>
                                                    <li><a href="#">Film & Video</a></li>
                                                    <li><a href="#">Graphic Design</a></li>
                                                    <li><a href="#">UI/UX Design</a></li>
                                                    <li><a href="#">Business Analytics</a></li>
                                                    <li><a href="#">Marketing</a></li>
                                                </ul>

                                            </div> */}

                                        <div class="footer-widget">
                                            <h4 class="footer-widget-title">Contacts</h4>

                                            <ul class="widget-info">
                                                <li>
                                                    <p><i class="flaticon-phone-call"></i> <a
                                                        href="Sugar Land TX 77479">
                                                        Missouri City, TX 77459-5225</a></p>
                                                </li>
                                                <li>
                                                    <p><i class="flaticon-phone-call"></i> <a href="tel:6893440">
                                                        +1 (302) 689 3440</a></p>
                                                </li>
                                                <li>
                                                    <p><i class="flaticon-email"></i> <a
                                                        href="mailto:address@gmail.com">
                                                        contact@utrains.org </a></p>
                                                </li>

                                            </ul>

                                        </div>

                                    </div>

                                </div>
                                <div class="col-lg-3 col-sm-3 col-md-6 order-md-2 order-lg-3">

                                    <div class="footer-widget">
                                        <h4 class="footer-widget-title">Links</h4>

                                        <ul class="widget-link">
                                            <li><a href={wordpressUrl} to="/" className="colorgraylink"><span>
							     {/* <i aria-hidden="true" class="fas fa-home"></i> */} 	</span>Home</a></li>
                                            <li><p><a href={wordpressUrl + "/contact-us/"} className="colorgraylink">
                                            <span>
							                    {/* <i aria-hidden="true" class="flaticon-phone-call"></i> */}</span>
                                                Contact</a></p></li>
                                            <li><a href={wordpressUrl + "/support/"} className="colorgraylink">
                                            <span>
							                {/* <i aria-hidden="true" class="icon icon-support1"></i> */}</span>
                                                Support</a></li>
                                            <li><p><a href={wordpressUrl + "/feedback/"} className="colorgraylink">
                                            <span>
							                    {/* <i aria-hidden="true" class="fas fa-hand-holding-medical"></i> */}</span>
                                                Feedback</a></p></li>
                                            <li><a href={wordpressUrl + "/testimonials/"}>
                                            <span>
							                    <i aria-hidden="true" class="flaticon-book"></i></span>
                                                Testimonials</a></li>
                                            <li><a href={wordpressUrl + "/faq/"} className="colorgraylink">
                                            <span>
							                    {/* <i aria-hidden="true" class="icon icon-faq"></i> */}</span>
                                                FAQ</a></li>
                                            {/*  <li><Link to="/space">user Space</Link></li>
                                                <li><Link to="/admin">Admin Space</Link></li> */}
                                        </ul>

                                        {/* <h4 class="footer-widget-title">Subscribe</h4>

                                        <div class="widget-subscribe">
                                            <p>For each course, we allow you to enroll for a session. List available
                                                courses, enroll for a session and start learning.</p>

                                            <div class="widget-form">
                                                <form  id="newsletter-form">
                                                    <input type="text" name="email" placeholder="Email here"
                                                           onChange={this.handleFormChange}/>
                                                    <button type="submit" class="btn btn-primary btn-hover-dark"
                                                            onClick={this.registerNewsletter}>Subscribe Now
                                                    </button>
                                                </form>
                                            </div>
                                        </div> */}
                                    </div>

                                </div>
                            </div>
                        </div>


                    </div>
                    <center>
                        <div className="container">
                            <div className="row">
                                <div className="col-3"></div>
                                <div className="col-2">
                                    <a href={wordpressUrl + "/terms-of-use/"} className="colorgraylink">Terms of use</a>
                                </div>
                                <div className="col-2">
                                    <a href={wordpressUrl + "/privacy-policy"} className="colorgraylink">Privacy
                                        Policy</a>
                                </div>
                                <div className="col-2">
                                    <a href={wordpressUrl + "/return-policy/"} className="colorgraylink">Return
                                        Policy</a>
                                </div>
                                <div className="col-3"></div>
                            </div>
                        </div>
                    </center>
                    <hr></hr>

                    <div class="footer-copyright" style={{background: "#000000"}}>
                        <div class="container">

                            <div class="copyright-wrapper">
                                <div class="copyright-link colorgray">
                                    <p>© All rights reserved</p>

                                </div>
                                <div class="copyright-text colorgray">
                                    <p>Powered ❤ by utrains </p>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div
                        className="position-fixed bottom-1 end-1 z-index-2">


                        {this.state.successMessage ? (
                                <div
                                    className="toast fade show p-2 mt-2 bg-gradient-info"
                                    role="alert"
                                    aria-live="assertive" id="infoToast"
                                    aria-atomic="true">
                                    <div
                                        className="toast-header bg-transparent border-0">
                                        <i className="material-icons text-white me-2">
                                            check
                                        </i>
                                        <span
                                            className="me-auto text-white font-weight-bold">Profile </span>
                                        <small className="text-white">just
                                            now</small>
                                        <i className="fas fa-times text-md text-white ms-3 cursor-pointer"
                                           data-bs-dismiss="toast"
                                           aria-label="Close"></i>
                                    </div>
                                    <hr className="horizontal light m-0"/>
                                    <div className="toast-body text-white">
                                        {this.state.message}
                                    </div>
                                </div>
                            ) :
                            (<></>)}
                        {this.state.failedMessage ? (
                                <div
                                    className="toast fade show p-2 mt-2 bg-gradient-danger"
                                    role="alert"
                                    aria-live="assertive" id="infoToast"
                                    aria-atomic="true">
                                    <div
                                        className="toast-header bg-transparent border-0">
                                        <i className="material-icons text-white me-2">
                                            check
                                        </i>
                                        <span
                                            className="me-auto text-white font-weight-bold">Profile </span>
                                        <small className="text-white">just
                                            now</small>
                                        <i className="fas fa-times text-md text-white ms-3 cursor-pointer"
                                           data-bs-dismiss="toast"
                                           aria-label="Close"></i>
                                    </div>
                                    <hr className="horizontal light m-0"/>
                                    <div className="toast-body text-white">
                                        {this.state.message}
                                    </div>
                                </div>
                            ) :
                            (<></>)}


                    </div>
                </div>

                <a href="#" class="back-to-top">
                    <i class="icofont-simple-up"></i>
                </a>

            </div>
        )
    }
}

export default Footer