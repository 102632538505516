/*
 *   This file is to define constants value that will be used inside the code
 *
 *
 * */

// This regex is for controlling the input email. This first one will be absolutely used in production
//export const EMAIL_REGEX = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

//This regex is for controlling the input email. This first one will be exclusively used in test environment
import moment from "moment-timezone";

// eslint-disable-next-line
export const EMAIL_REGEX = /^\w+([\.-]?\S+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

// This regex is to control the strength of a password
export const PASSWORD_REGEX =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d@$!%*?&#]{6,}$/;

const minute = 1000 * 60;

const hour = minute * 60;
export const day = hour * 24;

export const week = day * 7;
export const year = day * 365;

/*This value is for dev use only for auth settings*/
//export const AUTH_SETTINGS_DELAY = minute * 30;
/*This value is for production use only for auth settings*/
export const AUTH_SETTINGS_DELAY = day;

export const AUTH_SETTINGS_MINUTE = minute;

export const computeDateToTimeZone = (date, timezone) => {
  if (date !== null && date !== undefined) {
    let year =
      date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
    let hours =
      date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();
    let fullDate = year + " " + hours;

    let computedDate = moment.tz(fullDate, timezone);
    // return new Date(lastDate);
    return computedDate.utc().format();
  }
};

export const encodeUrl = (url) => {
  return url.replace(/ /g, "%20");
};

export const accordionNames = [
  "Sessions management",
  "Users Administration",
  "Settings",
];

// export const logoutRedirectUrl = () => {
//   window.location =
//       process.env.REACT_APP_WORDPRESS + process.env.REACT_APP_WP_DECONNECTION_URL;
// };

export const logoutRedirectUrl = () => {
  window.location =
      // process.env.REACT_APP_WORDPRESS + process.env.REACT_APP_LMS_DECONNECTION_URL;
      process.env.REACT_APP_COURSE_LMS + process.env.REACT_APP_LMS_DECONNECTION_URL;
     
};

// export const logoutRedirectUrl = () => {
//   const courseLmsUrl = process.env.REACT_APP_COURSE_LMS.replace('https://', 'http://');
//   window.location = courseLmsUrl + process.env.REACT_APP_LMS_DECONNECTION_URL;
// };

export const singleName = (paymentIntervalType) => {
  switch (paymentIntervalType) {
    case "days":
      return "day";
    case "weeks":
      return "week";
    case "months":
      return "month";
    case "years":
      return "year";

    default:
      return paymentIntervalType;
  }
}

export const isSettingSavable = (message, preview) => {
  return !(message !== "" || preview !== null);
};
